import React from 'react';
import './seeall.css';

function SeeAllButton() {
    return (
      <button className="seebtn bg-black text-white text-2xl border-2 border-white px-5 py-2.5 rounded-full ">
        Know More
      </button>
    );
  }

export default SeeAllButton;
